import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEinHausInTirol = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet ein Haus in Tirol?" showCalc={false} />
            <Article>
                <p>
                    Haus, der Traum! Auf der Suche nach dem passenden Eigenheim ist vor allem eines wichtig: sich
                    möglichst früh einen Überblick über das Angebot zu verschaffen und ein Gespür für regionale
                    Unterschiede auf dem Markt zu entwickeln. In den Artikeln dieser Serie widmen wir uns daher den
                    einzelnen Bundesländern und geben dir einen ersten Überblick über das dortige Angebot. Diesmal
                    nehmen wir den Immobilienmarkt in Tirol unter die Lupe.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Immobilienpreise in Österreich – aktuelle Tendenzen</h2>
                <p>
                    Die derzeit günstigen Kreditzinsen haben zur Folge, dass sich aktuell besonders viele Menschen eine
                    Investition in Immobilien leisten können. Die erhöhte Nachfrage treibt allerdings auch die Preise in
                    die Höhe: Ihr Anstieg liegt sich in Österreich seit dem vierten Quartal 2020 im zweistelligen
                    Prozentbereich. Im ersten Quartal 2021 wurden Immobilien dann um durchschnittlich 12,3 % teurer, im
                    zweiten um 11,7 %. Außerhalb Wiens verringerte sich der Anstieg der Kaufpreise für Einfamilienhäuser
                    2021 von 12,9 % im ersten auf 11,3 % im zweiten Quartal. Der Preisanstieg für gebrauchte
                    Eigentumswohnungen nahm im zweiten Quartal hingegen weiter zu: Diese wurden um 14,3 % teurer.
                    Beobachten lässt sich darüber hinaus, dass die Immobilienpreise außerhalb der Landeshauptstadt im
                    Allgemeinen rascher wachsen: Die Preise in Wien stiegen im ersten Quartal 2021 um 10,9, im zweiten
                    um 10,7 %. Zu Wachstumsraten von 14 bzw. 12,8 % kam es in diesem Zeitraum hingegen in den anderen
                    Bundesländern. Besonders für den Neubauwohnungsmarkt gilt dieser Trend: Stiegen die Preise für neue
                    Immobilien in Wien 2021 um 10,4 im ersten und von 10, 1 % zweiten Quartal, so kam es in den übrigen
                    Bundesländern zu einem Anstieg von 16,7 und 12,9 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Immobilienmarkt in Tirol</h2>
                <p>
                    Einige Tiroler Gebiete zeichnen sich durch einen lediglich geringen Anstieg der Mietpreise im Jahr
                    2021 aus: 0,3 % waren es in Kufstein, 2,6 % in Reutte, in Kitzbühel 3 % sowie 3,9 % Innsbruck. Sogar
                    rückläufig waren die Mietpreise in Imst: Mieten wurden hier um 0,46 % günstiger. Anders sieht es bei
                    Baugrundstücken aus. Diese sind in Tirol nicht gerade in großer Zahl vorhanden und folglich teuer:
                    Durchschnittlich beträgt der Preis für einen Quadratmeter 796,47 €.
                </p>
                <hr />

                <h2>Das kostet ein Haus in Tirol</h2>
                <p>
                    Der Immobilienmarkt in Tirol kann sogar die Preise im Zentrum Salzburgs toppen, zahlt man beim
                    Hauskauf in Innsbruck derzeit doch 3.045,00 € pro Quadratmeter. Noch mehr ist es in Kitzbühel:
                    Planst du, dich durch den Erwerb eines Hauses in die Österreichische Schickeria einzukaufen, dann
                    musst du tief in die Tasche greifen. Im beliebten Tourismusgebiet erwartet dich ein
                    Quadratmeterpreis von 3.245,13 €. Auch die Preise in Kufstein (2.365,00 €) und Landeck (2.292,56 €)
                    sind nicht gerade niedrig. Eine günstigere, aber etwas abgelegener Option ist Reutte, wo 1.513,67 €
                    pro Quadratmeter fällig werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEinHausInTirol"}></BreadcrumbList>
            <ArticleStructuredData page={"wasKostetEinHausInTirol"} heading={"Was kostet ein Haus in Tirol?"} />
        </Layout>
    );
};

export default WasKostetEinHausInTirol;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-ein-haus-in-tirol", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
